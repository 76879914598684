import React from 'react'
import './Contact.css'

import contactMe from './img/contact-me.png'
import text from './img/text.png'
import shoot from './img/shoot.png'
import github from './img/github.png'
import connect from './img/connect.png'
import insta from './img/insta.png'

const Contact = () => {
  return (
    <div className='Contact'>
      <div className='title'>
        <img src={contactMe} alt="" />
      </div>
      <div className='container'>
        <a href="tel: +17256002371 "><img src={text} alt="" /></a>
        <a href="mailto: lizellc@yahoo.com"><img src={shoot} alt="" /></a>
        <a href="https://github.com/LizellLee" target="_blank"><img src={github} alt="" /></a>
        <a href="https://www.linkedin.com/in/lizell-cabalquinto-a82135118" target="_blank"><img src={connect} alt="" /></a>
        <a href="https://instagram.com/lizc0des?igshid=YmMyMTA2M2Y=" target="_blank"><img src={insta} alt="" /></a>
      </div>
    </div>
  )
}

export default Contact