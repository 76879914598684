import React, { useEffect } from 'react'
import './PortfolioDV.css';

import welcome from './img/welcome.png';
import welcomeLight from './img/welcome-light.png';
import psyche from './img/psyche.png';
import dataknight from './img/dataknight.png';
import cobotnation from './img/cobotnation.png';
import alaska from './img/alaska.png';
import lasvegas from './img/lasvegas.png';
import uiux from './img/uiux.png';
import PortfolioCardDV from '../PortfolioCardDV/PortfolioCardDV';

const PortfolioDV = ({lightMode, setLigtMode}) => {
  useEffect(() => {
      const lightModeOn = () => {
        document.querySelector(".portfolio-dv").classList.add("light");
        if(document.querySelector(".portfolio-dv").classList.contains('dark')){
            document.querySelector(".portfolio-dv").classList.remove('dark')
          }
      }
      const lightModeOff = () => {
        document.querySelector(".portfolio-dv").classList.add("dark");
        if(document.querySelector(".portfolio-dv").classList.contains('light')){
            document.querySelector(".portfolio-dv").classList.remove('light')
          }
      }
    lightMode ? lightModeOn() : lightModeOff();
  });
  return (
    <div className='portfolio-dv'>
        <div className="welcome">
          <img id='welcome' src={lightMode ? welcomeLight : welcome} alt="" />
        </div>
        <div className='projects'>
            <div className='flex'>
                <PortfolioCardDV 
                  img={psyche} link="https://psyche.lizellc.com"
                  title="Capstone"
                  made="Ai, Figma, HTML, CSS, JS, A-Frame"
                  sponsor="NASA"
                  objective="Design and develop an AR web-based 
                  application that will be utilized in NASA’s exhibit."
                  tasks="Designed and developed landing page
                  Designed and Developed the “Exploration” 
                  AR experience."/>
                <PortfolioCardDV img={dataknight} link=""
                  title="App"
                  made="HTML, CSS, JS, React, React Hooks, MySQL"
                  sponsor="Cobot Nation"
                  objective="Design and Develop a web-based app that
                  tracks clients and cobots; with the capacity to create
                  various profile types, chat feature, locators, and track 
                  live cobot functionality."
                  tasks="UI design, UX mock and wire frame, UX 
                  Research/Testing, Full Stack development."/>
                <PortfolioCardDV img={cobotnation} link="https://cobotnation.com"
                  title="Website"
                  made="Ai, Figma, Wordpress, Elementor"
                  sponsor="Cobot Nation"
                  objective="Design and develop a fun, Art Deco-
                  esque themed website to engage consumers"
                  tasks="Designed and developed Wordpress website
                  utilizing the Elementor builder."/>
            </div>
            <div className='flex'>
                <PortfolioCardDV img={alaska} link="https://alaskafishingfanatics.com"
                  title="Website"
                  made="Wordpress, Elementor"
                  sponsor="Alaska Fishing Fanatics"
                  objective="Design and develop a modern & engaging 
                  themed website for customers to quickly find tours,
                  packages, lodging, services, and prices."
                  tasks="Designed and developed Wordpress website
                  utilizing the Elementor builder."/>
                <PortfolioCardDV img={lasvegas} link="https://lasvegasvideography.net"
                  title="Website"
                  made="Ai, Figma, HTML, CSS, JS, React"
                  sponsor="Las Vegas Videography"
                  objective="Design and develop a fun, profession 
                  themed website that customers can go to for 
                  packages, pricing, and photographer’s portfolio."
                  tasks="Designed and developed custom website
                  utilizing React."/>
                <PortfolioCardDV img={uiux} link=""
                  title="UI/UX"
                  made="Ai, Figma"
                  sponsor="Logos, Graphic Design, Wireframe Mocks"
                  objective="Design and develop a fun, profession 
                  themed website that customers can go to for 
                  packages, pricing, and photographer’s portfolio."/>
            </div>
        </div>
    </div>
  )
}

export default PortfolioDV