import React from 'react'
import './PortfolioCardDV.css';

const PortfolioCardDV = ({img, title, made, sponsor, objective, tasks, link}) => {
  return (
    <div className='portfoliocard-dv'>
        <div className='flipbox'>
            <div className="flip-box-inner">
                <div className="flip-box-front">
                    <img src={img} alt="" />
                </div>
                <div className="flip-box-back">
                    <div className='back-title'>
                        <h1>{title}</h1>
                        <p>{made}</p>
                    </div>
                    <p><strong>Sponsor:</strong> {sponsor}</p>
                    <p><strong>Objective:</strong> {objective}</p>
                    { tasks != "" && <p><strong>Tasks:</strong> {tasks}</p> }
                    <p><a href={link} target="_blank">Go to website</a></p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default PortfolioCardDV