import { useState, useEffect } from 'react';

import './App.css';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Contact from './pages/Contact/Contact';
import Menu from './components/Menu/Menu';
import Portfolio from './pages/Portfolio/Portfolio';
import Edit from './pages/Edit/Edit';
import CV from './pages/CV/CV';
import Desktop from './components/Desktop/Desktop';
import HomeDV from './pages/DesktopVersion/HomeDV/HomeDV';
import PortfolioDV from './pages/DesktopVersion/PortfolioDV/PortfolioDV';

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const [lightMode, setLightMode] = useState(false);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;
  return (
    <>
      {isMobile && <Menu lightMode={lightMode} setLightMode={setLightMode}/>}
      {/* <Menu /> */}
      { isMobile ? 
      <Routes>
        <Route path="/me" element={<Edit/>}/>
        <Route path="/portfolio" element={<Portfolio lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/" element={<Home lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/cv" element={<CV lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      :
      <Routes>
        <Route path="/portfolio" element={<Desktop screen={<PortfolioDV lightMode={lightMode} setLightMode={setLightMode}/>} lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/" element={<Desktop screen={<HomeDV lightMode={lightMode} setLightMode={setLightMode}/>} lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/cv" element={<Desktop screen={<CV lightMode={lightMode} setLightMode={setLightMode}/>} lightMode={lightMode} setLightMode={setLightMode}/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      } 
    </>
  );
}

export default App;
