import React from 'react'
import './HomeDV.css';

import hello from './img/hello.png';
import helloLight from './img/hello-light.png';

import email from './img/icons/email.svg';
import resume from './img/icons/resume.svg';
import instagram from './img/icons/instagram.svg';
import linkedin from './img/icons/linkedin.svg';
import github from './img/icons/github.svg';
import phone from './img/icons/phone.svg';

import emailLight from './img/icons/email-light.svg';
import resumeLight from './img/icons/resume.svg';
import instagramLight from './img/icons/instagram-light.svg';
import linkedinLight from './img/icons/linkedin-light.svg';
import githubLight from './img/icons/github-light.svg';
import phoneLight from './img/icons/phone-light.svg';

const HomeDV = ({lightMode, setLightMode}) => {
  return (
    <div className='home-dv'>
      <div className='hello-container'>
        <img id="hello" src={lightMode ? helloLight : hello} alt="" />
        <div className="insideHello">
          <div className="links">
              <a href='tel: +17256002371 '><img src={lightMode ? phoneLight : phone} alt="" /></a>
              <a href='mailto: lizellc@yahoo.com'><img src={lightMode ? emailLight : email} alt="" /></a>
              <a href='https://github.com/LizellLee' target='_blank'><img src={lightMode ? githubLight : github} alt="" /></a>
              <a href='https://www.linkedin.com/in/lizell-cabalquinto-a82135118' target='_blank'><img src={lightMode ? linkedinLight : linkedin} alt="" /></a>
              <a href='https://instagram.com/lizc0des?igshid=YmMyMTA2M2Y=' target='_blank'><img src={lightMode ? instagramLight : instagram} alt="" /></a>
              <a href='#'><img src={lightMode ? resumeLight : resume} alt="" /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeDV