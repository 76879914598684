import React from 'react'
// import { Swiper, SwiperSlide } from "swiper/react";
import './Portfolio.css'

// Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";

// import required modules
// import { Pagination } from "swiper";

import welcome from './img/welcome-to.png';
import welcomeLight from './img/welcome-to-light.png';

import psycheHead from './img/psyche-head.png';
import dataknightHead from './img/dataknight-head.png';
import alaskaHead from './img/alaska-head.png';
import uiuxHead from './img/uiux-head.png';
import lasvegasHead from './img/lasvegas-head.png';
import cobotHead from './img/cobot-head.png';
import { useEffect } from 'react';

import bg from './img/portfolio-bg.png';
import bgLight from './img/portfolio-light-bg.png';


const Portfolio = ({ lightMode, setLightMode }) => {
    useEffect(() => {
      const lightModeOn = () => {
        document.querySelector(".Portfolio").classList.add("light");
        if(document.querySelector(".Portfolio").classList.contains('dark')){
            document.querySelector(".Portfolio").classList.remove('dark')
          }
      }
      const lightModeOff = () => {
        document.querySelector(".Portfolio").classList.add("dark");
        if(document.querySelector(".Portfolio").classList.contains('light')){
            document.querySelector(".Portfolio").classList.remove('light')
          }
      }
    lightMode ? lightModeOn() : lightModeOff();
  });
  return (
    <div className='Portfolio'>
        <div className="welcome">
            <img src={lightMode ? welcomeLight : welcome} alt="" />
        </div>
        <div className='projects'>
            <div className="project psyche">
                <img className='headImg' src={psycheHead} alt="" />
                <p className='title'>Psyche Capstone</p>
                <p><span>Objective:</span> NASA Sponsored capstone project tasked to build an educational AR exp. for their exhibit. Landing page and web-based AR of “mission exploration” experience created as individual contribution. </p>
                <p><span>AR:</span> Ai, Figma, HTML, CSS, JS, A-Frame</p>
                <a href="https://psyche.lizellc.com" target="_blank" className='button'>See Project!</a>
            </div>
            <div className="project dataknight">
                <img className='headImg' src={dataknightHead} alt="" />
                <p className='title'>Data Knight Dashboard</p>
                <p><span>Objective:</span> Individually design and develop a web-based app that tracks clients and cobots; with the capacity to create various profile types, chat feature, locators, and track live cobot functionality. </p>
                <p>(user: lizc0des password: Liz123!)</p>
                <p><span>Web-Based App:</span> HTML, CSS, JS, React, React Hooks, MySQL</p>
                <a href="https://stage-dataknight12345.netlify.app" target="_blank" className='button'>See Project!</a>
            </div>
            <div className="project alaska">
                <img className='headImg' src={alaskaHead} alt="" />
                <p className='title'>Alaska Fishing Fanatics</p>
                <p><span>Objective:</span> Design and develop a modern & engaging themed website for customers to quickly find tours, packages, lodging, services, and prices.   </p>
                <p><span>Website:</span> Ai, Figma, Wordpress, Elementor</p>
                <a href="https://alaskafishingfanatics.com/" target="_blank" className='button'>See Project!</a>
            </div>
            <div className="project uiux">
                <img className='headImg' src={uiuxHead} alt="" />
                <p className='title'>UX | UI Projects</p>
                <p><span>Objective:</span>Take a look at my UX|UI and graphic design work. </p>
                <p><span>UX|UI:</span> Logos, Graphic Design, Wireframe Mocks Ai, Figma</p>
                <a href="" target="_blank" className='button'>See Project!</a>
            </div>
            <div className="project lasvegas">
                <img className='headImg' src={lasvegasHead} alt="" />
                <p className='title'>Las Vegas Videography</p>
                <p><span>Objective:</span> Design and develop a fun, profession themed website that customers can go to for packages, pricing, and photographer’s portfolio. </p>
                <p><span>Website:</span> Ai, Figma, HTML, CSS, JS, React</p>
                <a href="http://lasvegasvideography.net/" target="_blank" className='button'>See Project!</a>
            </div>
            <div className="project cobot">
                <img className='headImg' src={cobotHead} alt="" />
                <p className='title'>Cobot Nation</p>
                <p><span>Objective:</span> Design and develop a fun, art deco-esque themed website to engage consumers </p>
                <p><span>Website:</span> Ai, Figma, Wordpress, Elementor</p>
                <a href="https://cobotnation.com/" target="_blank" className='button'>See Project!</a>
            </div>
        </div>
    </div>
  )
}

export default Portfolio