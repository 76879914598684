import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import './MenuDV.css';

const MenuDV = ({ lightMode, setLightMode }) => {
  useEffect(() => {
    const lightModeOn = () => {
      document.querySelector(".menu-dv").classList.add('light');
      if(document.querySelector(".menu-dv").classList.contains('dark')){
        document.querySelector(".menu-dv").classList.remove('dark')
      }
      setLightMode(true);

    }
    const lightModeOff = () => {
      document.querySelector(".menu-dv").classList.add('dark');
      if(document.querySelector(".menu-dv").classList.contains('light')){
        document.querySelector(".menu-dv").classList.remove('light')
      }
      setLightMode(false);

    }

    lightMode ? lightModeOn() : lightModeOff();
  });
  
  return (
    <div className='menu-dv'>
        <Link to="/portfolio" id='portfolio'>Portfolio</Link>
        <Link to="/cv" id='cv'>CV</Link>
        {/* <a href="" id='portfolio' onClick={() => {setView('portfolio')} }>Portfolio</a>
        <a href="" id='cv' onClick={() => {setView('cv')}}>CV</a> */}
    </div>
  )
}

export default MenuDV