import React from 'react'
import './Home.css'
import { useState, useEffect } from 'react'

import Portrait from './img/Portrait'

import stars from './img/sky.png'
import cloud from './img/sky-light.png'
import hello from './img/hello.png'
import helloLight from './img/hello-light.png'
import PortraitMobile from './img/PortraitMobile'
import HomeDV from '../DesktopVersion/HomeDV/HomeDV'
import Desktop from '../../components/Desktop/Desktop'

import phone from './img/icons/phone.png'
import email from './img/icons/email.png'
import github from './img/icons/github.png'
import linkedin from './img/icons/linkedin.png'
import instagram from './img/icons/instagram.png'
import resume from './img/icons/resume.png'
import phoneLight from './img/icons/phone-light.png'
import emailLight from './img/icons/email-light.png'
import githubLight from './img/icons/github-light.png'
import linkedinLight from './img/icons/linkedin-light.png'
import instagramLight from './img/icons/instagram-light.png'
import resumeLight from './img/icons/resume-light.png'
import moon from './img/Moon.svg'
import sun from './img/Sun.svg'
import PortraitMobileLight from './img/PortraitMobileLight'



const Home = ({lightMode, setLightMode}) => {

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 768;

  useEffect(() => {
    const eyes = (e) => {
      const { clientX, clientY } = e;

      const eyes = document.querySelectorAll('.eyes');
      eyes.forEach(function(eye) {
        let x = (eye.getBoundingClientRect().left + (eye.clientWidth/2));
        let y = (eye.getBoundingClientRect().top + (eye.clientHeight/2));
        let radian = Math.atan2(clientX - x, clientY - y);
        let rot = (radian * (180 / Math.PI) * -1 ) + 280;
        eye.style.transform = "rotate(" + rot + "deg)";
      });
    }

    const eyebrows = (e) => {
      const {clientX, clientY} = e;

      const eyebrows = document.getElementById("eyebrows");
      let x = (eyebrows.getBoundingClientRect().left + (eyebrows.clientWidth/2));
      let y = (eyebrows.getBoundingClientRect().top + (eyebrows.clientHeight/2));
      let radian = Math.atan2(clientX - x, clientY - y) * 5;
      eyebrows.style.transform = "rotateX(" + radian + "deg)";
    }
    const lightModeOn = () => {
      document.querySelector(".Home").classList.add('light');
      if(document.querySelector(".Home").classList.contains('dark')){
        document.querySelector(".Home").classList.remove('dark')
      }
      setLightMode(true);

    }
    const lightModeOff = () => {
      document.querySelector(".Home").classList.add('dark');
      if(document.querySelector(".Home").classList.contains('light')){
        document.querySelector(".Home").classList.remove('light')
      }
      setLightMode(false);

    }

    lightMode ? lightModeOn() : lightModeOff();
    

    window.addEventListener("mousemove", eyes);
    window.addEventListener("mousemove", eyebrows);
    return () => {
      window.removeEventListener("mousemove", eyes);
      window.removeEventListener("mousemove", eyebrows);
    }
  });

  return (
    <>
      <div className='Home'>
        <div className="sky">
          <img id="moon" src={lightMode ? sun : moon} alt="" onClick={() => setLightMode(!lightMode)}/>
          <img id="display" src={lightMode ? cloud : stars} alt="" />
        </div>
        <div className='lizell'>
          {lightMode ? <PortraitMobileLight /> : <PortraitMobile/> }
          <div className='hello-container'> 
            <img id="helloImage" src={lightMode ? helloLight :  hello} alt="" />
            <div className='insideHello'>
{/*               <div className="lizellname">
                <img id='helloText' src={helloText} alt="" />
              </div> */}
              <div className='links'>
                <a href='tel: +17256002371 '><img src={lightMode ? phoneLight : phone} alt="" /></a>
                <a href='mailto: lizellc@yahoo.com'><img src={lightMode ? emailLight : email} alt="" /></a>
                <a href='https://github.com/LizellLee' target='_blank'><img src={lightMode ? githubLight : github} alt="" /></a>
                <a href='https://www.linkedin.com/in/lizell-cabalquinto-a82135118' target='_blank'><img src={lightMode ? linkedinLight : linkedin} alt="" /></a>
                <a href='https://instagram.com/lizc0des?igshid=YmMyMTA2M2Y=' target='_blank'><img src={lightMode ? instagramLight : instagram} alt="" /></a>
                <a href='#'><img src={lightMode ? resumeLight : resume} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home