import React, { useState, useEffect }from 'react'
import './Desktop.css'
import { Link } from 'react-router-dom'


import plant from './img/plant.png';
import contact from './img/contact.png';
import desktopImg from './img/desktop.png';
import moon from './img/moon.png';
import sun from './img/sun.png';
import portrait from './img/portrait.svg';
import readme from './img/readme.svg';
import lizell from './img/lizell.svg';
import stars from './img/stars.png';
import clouds from './img/clouds.png';
import info from './img/info.png';
import MenuDV from '../../pages/DesktopVersion/MenuDV/MenuDV';
import Modal from '../Modal/Modal';

const Desktop = ({screen, lightMode, setLightMode}) => {
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const lightModeOn = () => {
      document.querySelector(".Desktop").classList.add('light');
      if(document.querySelector(".Desktop").classList.contains('dark')){
        document.querySelector(".Desktop").classList.remove('dark')
      }
      setLightMode(true);

    }
    const lightModeOff = () => {
      document.querySelector(".Desktop").classList.add('dark');
      if(document.querySelector(".Desktop").classList.contains('light')){
        document.querySelector(".Desktop").classList.remove('light')
      }
      setLightMode(false);

    }

    lightMode ? lightModeOn() : lightModeOff();
  });
  return (
    <div className='Desktop'>
        { openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} lightMode={lightMode}/> }
        <img id='info' src={info} alt="" />
        <img id='stars' src={lightMode ? clouds : stars} alt="" />
        <div className='box'>
            <img id="plant" className='onFlat' src={plant} alt="" />
            <Link to="/contact"><img id="phone" className='onFlat' src={contact} alt="" /></Link>
            <div className='d-container'>
                {/* <i className="fa fa-bra nds fa-apple apple-logo" onClick={turnOnComputer}></i> */}
                <div id="screen" className='minimized'>
                {/* <Menu modals={modals} setModals={setModals}/> */}
                {/* <NSI /> */}
                {/* {screen} */}
                <MenuDV lightMode={lightMode} setLightMode={setLightMode}/>
                {/* {view} */}
                {screen}

                </div>
                <img id="desktop" className='onFlat' src={desktopImg} alt="" />
                <img id="moon" src={lightMode ? sun : moon} onClick={() => setLightMode(!lightMode)} alt="" />
            </div>
            <img id="lizell" className='onFlat' src={readme} alt="" onClick={() => setOpenModal(true)}/>
        </div>
        <div className="black-elevation"></div>
    </div>
  )
}

export default Desktop