import React, { useEffect } from 'react';
import './Modal.css';

import readme from './img/readme.png';
import readmeLight from './img/readme-light.png';
import ps from './img/ps.png';
import minimoon from './img/minimoon.png';
import minisun from './img/minisun.png';

const Modal = ({openModal, setOpenModal, lightMode}) => {
    useEffect(() => {
        const lightModeOn = () => {
          document.querySelector(".bg-modal").classList.add('light');
          if(document.querySelector(".bg-modal").classList.contains('dark')){
            document.querySelector(".bg-modal").classList.remove('dark')
          }
        }
        const lightModeOff = () => {
          document.querySelector(".bg-modal").classList.add('dark');
          if(document.querySelector(".bg-modal").classList.contains('light')){
            document.querySelector(".bg-modal").classList.remove('light')
          }
        }
    
        lightMode ? lightModeOn() : lightModeOff();
    });

  return (
    <div className="bg-modal">
        <div className="modal-container">
            <div className="close" onClick={() => { setOpenModal(false)}}>+</div>
            <img id="readme" src={lightMode ? readmeLight : readme} alt="" />
            <div>
            <p>
                Hi, welcome to my portfolio and thank you for taking the time to visit. My name is Liz, and I began my journey in tech by falling in love with UX/UI. At first I had no idea that this role even existed, and I even utilized PowerPoint to create mock ups of my app ideas. After finding out about tools such as Figma and Adobe xD, I followed my passion and decided to pursue my B.S. in software engineering at ASU. I began my journey 5 years ago, and along the way I picked up some design skills and other languages that have shaped me into a front-focused developer.
            </p>
            </div>
            <div className='PS'>
                <img src={ps} alt="P.S." />
                <p>Did you know there is a daytime and night time version! Just find and click the <img src={minisun} alt="Sun"/> or the <img src={minimoon} alt="Moon"/> ! </p>
            </div>
        </div>
    </div>
  )
}

export default Modal