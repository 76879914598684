import React from 'react'
import { Link } from 'react-router-dom'

import './Menu.css';

import write from './img/write.svg';
import home from './img/home.svg';
import cv from './img/cv.svg';

import writeLight from './img/write-light.svg';
import homeLight from './img/home-light.svg';
import cvLight from './img/cv-light.svg';
import { useEffect } from 'react';

const Menu = ({lightMode, setLightMode}) => {
  useEffect(() => {
    const menuColor = document.querySelectorAll(".Menu .navbar")[0];
    lightMode ? menuColor.style.background = "#FFFFFF" : menuColor.style.background = "#0A0211";
  });

  return (
    <div className='Menu'>
      <div className="navbar">
        {/* <Link to="/me"><img src={me} alt="" /></Link> */}
        <Link to="/portfolio"><img src={lightMode ? writeLight : write} alt="" /></Link>
        <Link to="/"><img src={lightMode ? homeLight : home} alt="" /></Link>
        <Link to="/cv"><img src={lightMode ? cvLight : cv} alt="" /></Link>
        {/* <Link to="/contact"><img src={phone} alt="" /></Link> */}
      </div>
    </div>
  )
}

export default Menu