import React, { useEffect } from 'react'

import './CV.css';

import cvTitle from './img/cv-title.png';
import cvTitleLight from './img/cv-light-title.png';
import summary from './img/summary.png';
import skills from './img/skills.png';
import work from './img/work.png';
import education from './img/education.png';
import skills1 from './img/skills1.png';
import skills2 from './img/skills2.png';

const CV = ({lightMode, setLightMode}) => {
    useEffect(() => {
        const lightModeOn = () => {
          document.querySelector(".CV").classList.add("light");
          if(document.querySelector(".CV").classList.contains('dark')){
            document.querySelector(".CV").classList.remove('dark')
          }
        }
        const lightModeOff = () => {
          document.querySelector(".CV").classList.add("dark");
          if(document.querySelector(".CV").classList.contains('light')){
            document.querySelector(".CV").classList.remove('light')
          }
        }
      lightMode ? lightModeOn() : lightModeOff();
    });

  return (
    <div className='CV'>
        <div className="title">
            <img src={lightMode ? cvTitleLight : cvTitle} alt="" />
        </div>
        <div className="container">
            <div className="header">
                <h1>Lizell Cabalquinto</h1>
                <p>(Creative Developer)</p>
                <p><red>import</red> location</p>
                <p>my_location <orange>=</orange> remote<violet>.</violet><blue>Only</blue><violet>( “</violet><orange>based_in_LV</orange><violet>”) </violet></p>
            </div>
            <div className="flex">
                <div className="numberline">
                    {/* <img id='numline' src={numberLine} alt="" /> */}
                </div>
                <div className="info-container">
                    <div className="summary">
                        <img className='info-title' src={summary} alt="" />
                        <p>Front - focused software engineer with expertise and experience in graphic design, UX/UI design and research, full stack development, testing, and maintenance. Consistently keeping up with the latest design trends, programming languages, and software tools. Efficiently able to self-manage independent projects while collaborating with other team members, especially within an agile environment. </p>
                    </div>
                    <div className="work">
                        <img className='info-title' src={work} alt="" />
                        <p className='flex-title'><purple>(Creative Developer)</purple></p>
                        <p>Meta         05.22 - present</p>
                        <p>Successfully fulfill <coral>stakeholder’s</coral> design and creative development requests by initiating <pink>intake meetings</pink> given by PMs, setting deadlines, <purple2>self-manage</purple2> tasks, <green>communicating</green> effectively with all parties involved, and <green2>collaborating</green2> with other <yellow>TechPubs</yellow>  (Meta’s in-house creative agency) teammates to ensure project <pink>efficiency</pink>.  </p>
                        <p className='flex-title'><purple>(UX/UI Developer)</purple></p>
                        <p>CobotNation      11.21 - 05.22</p>
                        <p>Tasked to create a website that complements their unique automation brand through <orange>Wordpress</orange>, and was later requested to independently design and build the DataKnight <teal>web-based application</teal> from scratch. Application was built by utilizing HTML, CSS, JS, <pink>React</pink>, React <blue>Hooks</blue>, & <green3>MySQL.</green3> </p>
                    </div>
                    <div className="education">
                        <img className='info-title' src={education} alt="" />
                        <div>
                            <p className='flex-title'><pink>(B.S. Software Engineer)</pink></p>
                            <p>Arizona State University</p>
                            <p>Graduate: May 2023</p>
                        </div>
                        <div>
                            <p className='flex-title'><pink>(A.B.A. Business Admin)</pink></p>
                            <p>College of Southern NV</p>
                            <p>Graduate: May 2015</p>
                        </div>
                    </div>
                    <div className="skills">
                        <img className='info-title' src={skills} alt="" />
                        <img src={skills1} alt="" />
                        <img src={skills2} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CV